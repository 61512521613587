export default function Footer() {
  return (
    <div class="bg-black 2xl:flex 2xl:items-center 2xl:justify-center lg:flex lg:items-center lg:justify-center relative 2xl:flex-row lg:flex-row md:flex-row flex-col py-[19px]">
      {/* <footer class="footer text-base text-white font-normal">
        @2024 <a href="/" title="snake game">Snake Game</a>
      </footer> */}
      <div className="lg:flex hidden absolute right-0 items-center mr-[38px]">
        <a className="text-base text-white font-semibold cursor-pointer" href="/about" title="About us - Snake Game">About Us</a>
        <a className="text-base text-white font-semibold ml-4 cursor-pointer" href="/dmca" title="DMCA Policy | Snake Game">DMCA</a>
        <a className="text-base text-white font-semibold ml-4 cursor-pointer" href="/contact-us" title="Contact Us - Snake Game">Contact Us</a>
        <a className="text-base text-white font-semibold ml-4 cursor-pointer" href="/policy" title="Privacy Policy">Privacy Policy</a>
        <a className="text-base text-white font-semibold ml-4 cursor-pointer" href="/term-of-use" title="Terms and Conditions">Term Of Use</a>
      </div>

      <div className="hidden lg:hidden md:flex items-center mr-[38px] flex-row justify-center">
        <a className="text-base text-white font-semibold cursor-pointer" href="/about" title="About us - Snake Game">About Us</a>
        <a className="text-base text-white font-semibold ml-4 cursor-pointer" href="/dmca" title="DMCA Policy | Snake Game">DMCA</a>
        <a className="text-base text-white font-semibold ml-4 cursor-pointer" href="/contact-us" title="Contact Us - Snake Game">Contact Us</a>
        <a className="text-base text-white font-semibold ml-4 cursor-pointer" href="/policy" title="Privacy Policy">Privacy Policy</a>
        <a className="text-base text-white font-semibold ml-4 cursor-pointer" href="/term-of-use" title="Terms and Conditions">Term Of Use</a>
      </div>

      <div className="flex lg:hidden md:hidden sm:hidden flex-col ml-6 mr-[38px]">
        <a className="text-base text-white font-semibold cursor-pointer" href="/about" title="About us - Snake Game">About Us</a>
        <a className="text-base text-white font-semibold cursor-pointer mt-6" href="/dmca" title="DMCA Policy | Snake Game">DMCA</a>
        <a className="text-base text-white font-semibold cursor-pointer mt-6" href="/contact-us" title="Contact Us - Snake Game">Contact Us</a>
        <a className="text-base text-white font-semibold cursor-pointer mt-6" href="/policy" title="Privacy Policy">Privacy Policy</a>
        <a className="text-base text-white font-semibold cursor-pointer mt-6" href="/term-of-use" title="Terms and Conditions">Term Of Use</a>
      </div>
    </div>
  )
}