import React, { useEffect } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import logo from "./assets/images/logo-header.png";
import logoFooter from "./assets/images/logo-footer.png";
import logoText from "./assets/images/logo-text.png";
import pattern1 from "./assets/images/pattern1.png";
import pattern2 from "./assets/images/pattern2.png";
import pattern3 from "./assets/images/pattern3.png";
import pattern4 from "./assets/images/pattern4.png";
import pattern5 from "./assets/images/pattern5.png";
import pattern6 from "./assets/images/pattern6.png";
import About from "./pages/about";
import ContactUs from "./pages/contactUs";
import DMCA from "./pages/dmca";
import Footer from "./pages/footer";
import Home from "./pages/home";
import Policy from "./pages/policy";
import TermOfUse from "./pages/termOfUse";

function App() {
  const location = useLocation();
  const currentPath = location.pathname;

  // React.useEffect(() => {
  //   document.title = process.env.REACT_APP_OG_TITLE;

  //   const metaDescription = document.querySelector("meta[property='og:description']");
  //   if (metaDescription) {
  //     metaDescription.setAttribute("content", process.env.REACT_APP_OG_DESCRIPTION);
  //   }

  //   const nameDescription = document.querySelector("meta[name='description']");
  //   if (nameDescription) {
  //     nameDescription.setAttribute("content", process.env.REACT_APP_OG_DESCRIPTION);
  //   }

  //   const metaTwitterDescription = document.querySelector("meta[name='twitter:description']");
  //   if (metaTwitterDescription) {
  //     metaTwitterDescription.setAttribute("content", process.env.REACT_APP_OG_DESCRIPTION);
  //   }

  //   const metaImage = document.querySelector("meta[property='og:image']");
  //   if (metaImage) {
  //     metaImage.setAttribute("content", process.env.REACT_APP_OG_IMAGE);
  //   }

  //   const metaImageSecure = document.querySelector("meta[property='og:image:secure_url']");
  //   if (metaImageSecure) {
  //     metaImageSecure.setAttribute("content", process.env.REACT_APP_OG_IMAGE_SECURE);
  //   }

  //   const canonicalLinks = document.querySelectorAll("meta[property='og:url']");
  //   canonicalLinks.forEach(link => {
  //     link.setAttribute("content", process.env.REACT_APP_LINK_URL);
  //   });
  // }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // if (!window.location.hash) {
    //   window.location.href = "/#";
    // }
  }, []);
  console.log(`version: ${process.env.REACT_APP_VERSION}`);
  return (
    <div
      className={`
        flex flex-col min-h-screen content-body relative overflow-x-hidden 
        ${currentPath.includes("dmca") ||
          currentPath.includes("about") ||
          currentPath.includes("policy") ||
          currentPath.includes("term-of-use") ||
          currentPath.includes("contact-us")
          ? "overflow-y-hidden"
          : ""
        }`}
    >
      <header className="flex items-center justify-between 2xl:px-60 lg:px-40 md:px-7 px-6 py-4">
        <Link to="/">
          <img className="2xl:w-[70px] 2xl:h-[70px] lg:w-[70px] lg:h-[70px] w-[40px] h-[40px]" src={logo} alt="logo" />
        </Link>
        <Link to="/">
          <img className="2xl:w-[320px] 2xl:h-[42px] lg:w-[240px] lg:h-[34px] w-[160px] h-[30px]" src={logoText} alt="logo-text" />
        </Link>
      </header>
      <div
        className={`absolute 2xl:w-[1000px] 2xl:h-[1000px] w-[900px] h-[900px] left-[-650px] 2xl:top-[15%] top-[10%] ${currentPath.includes("dmca") ||
          currentPath.includes("about") ||
          currentPath.includes("policy") ||
          currentPath.includes("term-of-use") ||
          currentPath.includes("contact-us")
          ? "2xl:hidden lg:hidden md:hidden hidden"
          : "2xl:block lg:block md:hidden hidden"
          }`}
      >
        <img src={pattern1} alt="pattern1" />
      </div>
      <div className={`absolute right-0 2xl:top-[25%] lg:top-[25%] top-[15%] 2xl:w-auto 2xl:h-auto lg:w-auto lg:h-auto w-[114px] h-[104px] ${currentPath.includes("dmca") ||
        currentPath.includes("about") ||
        currentPath.includes("policy") ||
        currentPath.includes("term-of-use") ||
        currentPath.includes("contact-us")
        ? "2xl:hidden lg:hidden md:hidden hidden"
          : "2xl:block lg:block md:hidden hidden"
        }`}>
        <img src={pattern2} alt="pattern2" />
      </div>
      <div
        className={`absolute left-[-200px] top-[49%]
                      ${currentPath.includes("dmca") ||
            currentPath.includes("policy") ||
            currentPath.includes("term-of-use") ||
            currentPath.includes("about") ||
            currentPath.includes("contact-us")
            ? "2xl:hidden lg:hidden md:hidden hidden"
            : "2xl:block lg:hidden md:hidden hidden"
          }`}
      >
        <img src={pattern3} alt="pattern3" />
      </div>
      <div
        className={`absolute right-[-560px] top-[60%]
        ${currentPath.includes("dmca") ||
            currentPath.includes("policy") ||
            currentPath.includes("term-of-use") ||
            currentPath.includes("about") ||
            currentPath.includes("contact-us")
            ? "2xl:hidden lg:hidden md:hidden hidden"
            : "2xl:block lg:hidden md:hidden hidden"
          }`}
      >
        <img src={pattern4} alt="pattern4" />
      </div>
      <div className="absolute left-[-80px] bottom-10 2xl:block lg:hidden md:hidden hidden">
        <img src={pattern5} alt="pattern5" />
      </div>
      <div className="absolute right-[-150px] bottom-10 2xl:block lg:hidden md:hidden hidden">
        <img src={pattern6} alt="pattern6" />
      </div>
      <Routes className="App ">
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/term-of-use" element={<TermOfUse />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/dmca" element={<DMCA />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <div className="flex items-center justify-center 2xl:mt-16 lg:mt-8 2xl:mb-16 lg:mb-10 mb-10 mt-6">
        <img
          src={logoFooter}
          alt="logo-footer"
          className="w-[100px] h-[100px]"
        // className="2xl:w-[243px] 2xl:h-[109px] lg:w-[109px] lg:h-[49px] w-[109px] h-[49px]"
        />
      </div>
      <Footer />
    </div>
  );
}

export default App;
